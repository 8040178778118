<template>
  <div>
    <v-snackbar v-model="snackbar.show" top right :color="snackbar.color" timeout="2000">
      <v-icon class="mr-2">{{ snackbar.icon }}</v-icon>
      <span> {{ snackbar.text }} </span>

      <template v-slot:action="{ attrs }">
            <v-btn
              color="white"
              icon
              v-bind="attrs"
              @click="snackbar.show = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
    </v-snackbar>
    <v-row justify="center">
      <v-col cols="12" class=" w-100 text-center" v-if="loading">
        <v-progress-circular
        :size="64"
        color="grey"
        indeterminate
        class="text-center px-12 " >
        </v-progress-circular>
      </v-col>
      <v-col v-else
        cols="12"
        sm="12"
        md="4"
        lg="4"
        xl="4"
        v-for="(product,index) in arrays.products"
        :key="index"
      >
        <v-card  class="mx-auto" outlined>
          <v-card-title > <a :href="`${product.url}`" class="black--text" >{{ product.name }} </a>  </v-card-title>
          <v-card-subtitle class="text-uppercase" v-if="arrays.me">
            <span class="green--text" v-if="product.end_of_subscription && !product.expired"> Subscribed </span>
            <span class="red--text" v-if="product.expired"> Expired </span> 
            <span class="blue--text" v-if="!product.end_of_subscription"> For Trial </span> 
          </v-card-subtitle>
          <div class="p-3">
            <a :href="`${product.url}`" >
                <v-img
                  height="120"
                  src="@/assets/product.svg"
                ></v-img>
            </a>
          </div>
          <v-card-text>
            <v-expansion-panels flat class="border mb-2" >
              <v-expansion-panel >
                <v-expansion-panel-header>
                  Details
                </v-expansion-panel-header>
                <v-expansion-panel-content >
                  <small class="text-caption text-secondary text-uppercase"> Description </small>
                  <p class="black--text  mb-2">
                      {{ product.description }} 
                  </p>
                  <small class="text-caption text-secondary text-uppercase"> Product Type </small>
                  <p class="black--text w-100 text-uppercase mb-1"> {{ product.type }} </p>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
             
             <div v-if="product.subs_plan && product.subs_plan.length > 0" >
               <p class=" mb-1"> Subscription Plans </p>
                <div class="d-flex align-center justify-space-around flex-wrap" >
                  <p  class="w-50 d-block m-0 p-1 mb-1  black--text font-weight-bold " v-for="(item,index) in product.subs_plan" :key="index">
                    <span class=" text-uppercase">{{ item.plan }}</span>
                  </p>
              </div>
             </div>
            <div class="d-flex align-center justify-space-around flex-wrap" v-else>
              <span  class="w-50 d-block">
                <small class="text-caption text-secondary text-uppercase"> monthly</small>
                <p class="black--text font-weight-bold mb-1 " v-if="product.monthly_price > 0">{{ product.monthly_price | currency(true) }} </p>
                <p v-else class="black--text font-weight-bold mb-1"> N/A </p>

              </span>
              <span  class="w-50 d-block">
                <small class="text-caption text-secondary text-uppercase"> quarterly</small>
                <p class="black--text font-weight-bold mb-1 " v-if="product.quarterly_price > 0">{{ product.quarterly_price | currency(true) }} </p> 
                <p v-else class="black--text font-weight-bold mb-1"> N/A </p>

              </span>
              <span  class="w-50 d-block">
                <small class="text-caption text-secondary text-uppercase"> semi ANNUALLY</small>
                <p class="black--text font-weight-bold mb-1 " v-if="product.semi_annual_price > 0">{{ product.semi_annual_price | currency(true) }} </p> 
                <p v-else class="black--text font-weight-bold mb-1"> N/A </p>

              </span>
              <span class="w-50 d-block">
                <small class="text-caption text-secondary text-uppercase"> annually</small>
                <p class="black--text font-weight-bold mb-1 " v-if="product.annually_price > 0">{{ product.annually_price | currency(true) }} </p> 
                <p v-else class="black--text font-weight-bold mb-1"> N/A </p>

              </span>
              <p class="text-secondary" v-if="status.reg_extend"> </p>
            </div>
            
            <div v-if="arrays.me">
                <small class="text-caption text-secondary text-uppercase"> Subscribed Until </small>
                
                <p class="black--text  mb-1" v-if="product.end_of_subscription && !status.admin">
                  {{ product.end_of_subscription | displayDatev2 }}
      
                </p>

                <p v-if="!product.end_of_subscription && status.admin" class="black--text  mb-1">
                  For Trial
                </p>

                <p class="black--text  mb-1" v-if="status.admin && product.end_of_subscription">
                  {{ product.end_of_subscription | displayDatev2 }} 
          
                </p>

                <!-- Regular User -->
                <p class="black--text  mb-1" v-if="!status.admin && !product.end_of_subscription">
                  For Trial
                </p>
            </div>
            <div v-if="arrays.me">
              <small class="text-caption text-secondary text-uppercase"> Refer your friends and earn! </small>
              <p class="black--text mb-1" v-if="product.referral_type">
                <router-link to="/account/yourinfo"> {{ product.referral_amount | currency(true) }} for every referral </router-link> 
              </p>
              <p class="black--text mb-1" v-else>
                <router-link to="/account/yourinfo"> {{ product.referral_amount }} % for every referral </router-link>  </p>
            </div>
            <p class="black--text" v-if="!status.admin && status.reg_extend && product.end_of_subscription ">To extend this product, please ask the owner of the company to purchase subscription. </p>
            <p class="black--text mb-0" v-if="!status.admin && status.reg_try && !product.end_of_subscription ">To try this product. please ask to owner to avail the free trial period</p>
          </v-card-text>
           <v-card-actions class="border-top" v-if="product.end_of_subscription">
             <div class="text-right d-block w-100 ">
               <v-btn class="text-secondary" text @click="gotoProduct(product.id)">
                  Details
                </v-btn>
               <v-btn
                  color="#0A009B"
                  text
                 @click="subscribe(product)"
                  v-if="status.admin"
                  >
                    EXTEND
                  </v-btn >
                  <v-btn v-else color="#0A009B"
                  @click="status.reg_extend = true"
                      text>
                      Extend
                  </v-btn>
             </div>
            </v-card-actions>
            <v-card-actions class="border-top" v-else>
              <div class="d-block text-right w-100 ">
                <v-btn color="#0A009B" text @click="gotoProduct(product.id)">
                  View Details
                </v-btn>
                <!-- <v-btn v-if="status.admin" @click="goToDetail">
                  
                </v-btn> -->
                <!-- <trial v-if="status.admin"
                v-bind:product="product" 
                v-bind:myInfo="arrays.me" 
                v-bind:users="arrays.users_in_company"
                @transaction="refresh"
                > </trial> -->
                <!-- <v-btn v-else color="#0A009B"
                @click="checkTry"
                  text>
                  Try Now
                </v-btn> -->
              </div>
            </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

  </div>
</template>
<script>
// import trial from '@/components/Product/Trial'
export default {
  components: {
    // trial
  },
  props: ['info'],
  data() {
    return {
      dialogs: {
        delete: false,
        data: null,
      },
      snackbar: {
        show: false,
        icon: "mdi-check",
        text: "",
        color: "success",
      },
      arrays: {
        users_in_company: [],
        me : null,
        products: [],
        subs_plan : [],
      },
      status: {
        invited : false,
        admin : false,
        reg_extend : false,
        reg_try : false,
        subs_plan : false
      },
      pagination: {
        users: null,
      },
      loading: false,
    };
  },
  created() {

    this.loading = true;
    if(this.info)
    {
      this.arrays.me = this.info
      this.checkUserRole()
      this.getProducts()
      this.checkInviter()

    }else{
      this.publicProducts()
    }
  },
  watch: {
    info : function(val)
    {
      if(val)
      {
        this.arrays.me = val
        this.getProducts()
         this.checkUserRole()
        this.checkInviter()
      }
    }
  },
  methods: {
    visitProduct(url)
    {
      if(url)
      {
        window.location.href = url
      }
     
    },
    checkTry()
    {
      if(this.arrays.me)
      {
        this.status.reg_try = true
      }else{
        window.location.href = this.$config.login
      }
       
    },
    gotoProduct(id)
    {
      this.$router.push({path: '/product/'+id } )
    },
    getUsersinCompany() {
      this.loading = true;
      if(this.status.admin)
      {
        this.axios
        .get("/subscriptions?time_difference="+this.$options.filters.getTimezone(true)+"&company_id=" + this.arrays.me.default_user_company.company_id + '&latest_expiration=true')
        .then(({ data }) => {
          this.pagination.users = data.meta
          this.arrays.users_in_company = data.subscription
          for(let i in this.arrays.products)
          {
            if(this.arrays.products[i].type == 'per user')
            {
              this.arrays.products[i].users = this.getUsers(this.arrays.products[i].id)
            }
          }
          this.loading = false;
        })
      }
      
    },
    getUsers(id)
    {
      let users = []
      for(let i in this.arrays.users_in_company)
      {
        if(!isNaN(i))
        {
          
          if(this.arrays.users_in_company[i].products_id == id)
          {

            users.push(this.arrays.users_in_company[i])
          }
        }
      }
      return users
    },
    arrangeUser(product) {
      let arrange = [];
      let addDays = this.status.invited ? 30 : 10
      let billing_date = this.arrays.me.default_user_company.company.billing_date
      if (this.arrays.users_in_company && this.arrays.users_in_company.length > 0)
      {
        for (let u in this.arrays.users_in_company) {
          if (!isNaN(u)) {
            arrange[u] = {
              products_id: product.id,
              start_of_subscription: this.$options.filters.today(),
              end_of_subscription: this.$options.filters.addDay(
                this.$options.filters.today(),
                addDays
              ),
              user_company_id: this.arrays.users_in_company[u].user_company_id,
              subscription_amount: 0,
              transaction_id: 0,
              status: "paid",
            };
          }
        }
        return arrange;
      }
     else{
      let start = this.$options.filters.userStartofSubscription( billing_date )
      let end = this.$options.filters.trialDate(start, this.status.invited)
      let user_company_id = this.arrays.me.default_user_company.id

       let data = [{
        products_id: product.id,
        start_of_subscription: start,
        end_of_subscription: end,
        user_company_id: user_company_id,
        subscription_amount: 0,
        transaction_id: 0,
        status: "paid",
       }]
       return data
      }
 
    },
    trial(product) {
      if (!this.$user.SuccessLogin()) {
        window.location.href = this.$config.login
      } else {
        let data = {
          payment_method_id: 3,
          payment_method_wallet : null,
          user_id : this.arrays.me.id,
          transaction_date: this.$options.filters.today(),
          product_id : product.id,
          payment_amount: 0,
          total_amount: 0,
          paypal_transaction_id: null,
          inviter : null,
          wallet_id: this.arrays.me.id,
          wallet_amount: 0,
          type: 0,
          hasSubscription: this.arrangeUser(product),
        };
        this.snackbarTrial(product)
        this.axios.post("/accounting", data).then(() => {
          this.snackbar.show = true;
          this.getProducts()
          this.getUsersinCompany()
        });
      }
    },
    checkInviter()
    {
      this.axios.get('/inviter?user_id='+this.arrays.me.id)
      .then(({data}) => {
        data.inviter.length > 0 ? this.status.invited = true : this.status.invited = false
      })
    },
    subscribe(product) {
      if (!this.$user.SuccessLogin()) {
        window.location.href = this.$config.login;
      }else{
        this.$router.push({ name: 'SubscribeProduct', params: { id: product.id }  })
      }
    },
    getProducts() {
      this.loading = true
      this.arrays.products = null
      let user_company_id = null
      this.checkUserRole()
      if(this.arrays.me)
      {
        if(this.arrays.me.default_user_company)
        {
          user_company_id = this.arrays.me.default_user_company.id
        }
        this.axios.get("/products", {params : {
          user_company_id : user_company_id,
          subscription : true,
          with_subs_plan : true,
          for_public : true,
        }}).then(({ data }) => {
          this.getUsersinCompany()
          this.arrays.products = null
          this.arrays.products = this.appendUsers(data.products)
          this.prepareLink()
          this.loading = false

        });
      }
      
    },
    prepareLink()
    {
      if(this.arrays.products && this.arrays.me)
      {
        let user_company_id = this.arrays.me.default_user_company.id
        let company_id = this.arrays.me.default_user_company.company.id
        for(let i in this.arrays.products)
        {
           this.arrays.products[i].link = this.$options.filters.productLink(this.arrays.products[i].name, this.arrays.products[i].url, user_company_id, company_id)
        }
      }
    },
    publicProducts()
    {
      this.axios.get('/products', {params : {
        with_subs_plan : true,
        for_public : true,
      }})
      .then( ({data}) => {
        this.arrays.products = data.products
        this.loading = false
      })
    },

    appendUsers(products)
    {
      for(let i in products)
      {
        if(products[i].type == 'per user')
        {
          products[i]['users'] = 0
        }
        if(products[i].end_of_subscription)
        {
          products[i].expired = this.$options.filters.checkEndOfSubscription(products[i].end_of_subscription)
        }
      }
      return products
    },
    checkUserRole()
    {
      if(this.arrays.me)
      {
        if(this.arrays.me.default_user_company)
        {
          this.arrays.me.default_user_company.role == 'SA' ? this.status.admin = true : this.status.admin = false
        }
        
      }
    },
    unsubscribe(data) {
      this.dialogs.data = data;
      this.dialogs.delete = true;
    },
    refresh(product) {
      if(product)
      {
        this.getProducts()
        this.snackbarTrial(product);
      }else{
        this.snackbar.color = "error"
        this.snackbar.text = "Failed to start Trial"
        this.snackbar.icon = 'mdi-close-box-outline'
      }
      
      this.snackbar.show = true;
    },
    snackbarTrial(product) {
      this.snackbar.show = false;
      this.snackbar.text = product.name + " was subscribe for trial";
      this.snackbar.icon = "mdi-check";
      this.snackbar.color = "success";
    },
  },
};
</script>

<style scoped>
.btn {
  text-transform: none;
}
.text-money{
 color: #ffa600;
 font-weight: bold;
}
</style>